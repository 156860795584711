<template>
  <el-container class="app-main">
    <el-aside class="aside" :width="$store.getters.aside_show ? 'auto' : '200px'">
      <common-aside ref="aside" />
    </el-aside>
    <el-container>
      <el-header class="header" height="80">
        <common-head ref="head" />
      </el-header>
      <el-main class="main">
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key" />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import CommonAside from "./components/Aside/CommonAside.vue";
import CommonHead from "./components/header/CommonHead.vue";

export default {
  name: "Main",
  components: {
    CommonAside,
    CommonHead,
  },
  data() {
    return {
    };
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  created() {
  },
  mounted() { },
  
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  height: 100vh;
  width: 100%;

  .aside {
    // width: 200px;
    height: 100%;
    overflow: hidden;
  }

  .header {
    padding: 0 20px 0 10px;
    // background-color: rgba(41, 136, 254, 1);#ffd04b
    // background-color: #fff;
    // background-color: #2988fe;
  }

  .main {
    padding: 20px 30px;
    background-color: #f0f2f5;
  }
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
