
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  console.log('to', to.path);
  console.log('from', from.path);
  // console.log('to.meta', to.meta);
  // console.log('to.meta.title', to.meta.title);
  // set page title
  // await store.dispatch("agent/getMaterial")
  // document.title = store.getters.material_data.system_name || 'AI直播魔盒'
  document.title = 'AI直播魔盒'
  // document.querySelector('link[rel="icon"]').setAttribute('href', store.getters.material_data.icon_img)
  
  // determine whether the user has logged in
  const EntryPath = store.getters.path
  const hasToken = store.getters.token || getToken()
  console.log('hasToken', hasToken);
  if (hasToken) {
    // if(from.path === '/login'){
    //   await store.dispatch('user/getInfo').then((res)=>{
    //     console.log('permission / getInfo', res);
    //     if(res.identity === 'customer'){

    //     }
    //   })
    // }
    if (to.path === '/login') {
      await store.dispatch("user/getInfo").catch((err) => {
        if (err == 401) {
          store.dispatch("user/logout");
        }
        next({
          path: EntryPath,
          replace: true
        })
        return false
      })
      next(EntryPath)
    } else {

      try {
        if (from.path !== to.path) {
          // if(from.path === '/login'){
          // await store.dispatch('menu/getSts')
          console.log('to.path111', to.path);
          // await store.dispatch("user/getInfo").catch((err) => {
          //   if (err == 401) {
          //     store.dispatch("user/logout");
          //   }
          //   next({
          //     path: '/login',
          //     replace: true
          //   })
          //   return false
          // })
          store.dispatch('user/setPathTo', to.path)
          // }

          // if(to.path === '/accountList'){
          //   timeDestruction()
          // }
          // console.log('user_info', user_info);
          console.log('to.path222', to.path);
          next()
        } else {
          // await store.dispatch("user/logout");
          // await store.dispatch("user/getInfo").catch((err) => {
          //   if (err == 401) {
          //     store.dispatch("user/logout");
          //   }
          //   next({
          //     path: '/login',
          //     replace: true
          //   })
          //   return false
          // })
          // next({
          //   path: EntryPath,
          //   replace: true
          // })
          next()
          return false
        }

      } catch (error) {
        // remove token and go to login page to re-login
        await store.dispatch("user/logout");
        Message.error(error || 'Has Error')
        next({
          path: '/login',
          replace: true
        })
      }
    }
    // }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
})
