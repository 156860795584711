import request from '@/utils/axios.js'


// 获取视频素材列表
export function getVideoList(data) {
  return request({
    url: '/video',
    method: 'get',
    params: data
  })
}

// 添加视频素材
export function addVideo(data) {
  return request({
    url: '/video',
    method: 'post',
    data
  })
}

// 修改视频素材
export function updateVideo(data) {
  return request({
    url: `/video/${data.id}`,
    method: 'put',
    data: data.data
  })
}



// 删除视频素材
export function deleteVideo(id) {
  return request({
    url: `/video/${id}`,
    method: 'delete',
  })
}