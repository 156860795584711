import { getToken, setToken, removeToken } from '@/utils/auth'
import { login, getInfo, changePassword, getMerchantListNoPaging, getMerchantList, addMerchant, getMaterial, getOemSetting, setOemSetting, getPackageList, MerchantRenewal, MerchantChangePwd, setUserEdit } from "@/api/user";
import router from '@/router';


const getDefaultState = () => {
  return {
    token1: "",
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkuYmV0YS54aGNzYWguY29tXC9jbGllbnRcL2RvdXlpblwvZ2V0TG9naW5VcmwiLCJpYXQiOjE2ODY2MTg3NjgsImV4cCI6MTY4Njc5ODc2OCwibmJmIjoxNjg2NjE4NzY4LCJqdGkiOiJ0WVNJekFiam9paWZYczhUIiwic3ViIjoyLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.1LPJ__y3TgGFV-2mvPpvC5vlrx2rIKqoy8fFllaP8aM',
    path: "/GroupPurchaseAccount",
    path_to: '/GroupPurchaseAccount',
    name: '',
    username: '',
    identity: '',
    surplus_merchant: 0,
    user_info: {},
    merchant_list: {},
    // material_data: {},
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_PATH: (state, path) => {
    state.path = path;
  },
  SET_PATH_TO: (state, path_to) => {
    state.path_to = path_to;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_USER_INFO: (state, user_info) => {
    state.user_info = user_info;
  },
  SET_MERCHANT_LIST: (state, merchant_list) => {
    state.merchant_list = merchant_list;
  },
  SET_IDENTITY: (state, identity) => {
    state.identity = identity;
  },
  SET_SURPLUS_MERCHANT: (state, surplus_merchant) => {
    state.surplus_merchant = surplus_merchant;
  },
  // SET_MATERIAL_DATA: (state, material_data) => {
  //   state.material_data = material_data;
  // },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      const { username, password } = userInfo;
      console.log('username', username);
      console.log('password', password);
      login({
        username,
        password
      })
        .then((response) => {
          const { data } = response;
          // console.log('user login', data);
          if (data.code === 200) {
            const token = data.data;
            // console.log('loginresponse', token);
            commit("SET_TOKEN", token);
            // console.log('SET_TOKEN', store.getters.token);
            setToken(token)
            // console.log('getToken', getToken());
            resolve('登录成功')
          } else if (data.code === 401) {
            reject('账号或密码错误');
          } else if (data.code === 500) {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const data = response.data;
          console.log('get user info', data);
          if (data.code === 200) {
            const { name, username, identity } = data.data;
            commit("SET_NAME", name);
            commit("SET_USERNAME", username);
            commit("SET_IDENTITY", identity);
            // if (identity !== 'customer' && data.data.agent.parent_id === 0) {
            //   commit("SET_SURPLUS_MERCHANT", data.data.agent.surplus_merchant);
            // }
            commit("SET_USER_INFO", data.data);
            commit("SET_PATH", '/GroupPurchaseAccount');
            resolve(data.data);
          } else if (data.code === 401) {
            // removeToken() // must remove  token  first
            // commit("RESET_STATE");
            // localStorage.removeItem("keywords")
            reject(data.code);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // change Password
  changePassword({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      changePassword(data)
        .then((response) => {
          const data = response.data;
          // console.log('changePassword', data);
          if (data.code === 200) {
            resolve('修改成功');
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // getMerchantListNoPaging
  getMerchantListNoPaging({ commit, state }, val, tf) {
    return new Promise((resolve, reject) => {
      const data = {
        name: val ? val.name : ''
      }
      getMerchantListNoPaging(data, tf)
        .then((response) => {
          const data = response.data;
          // console.log('商户列表(无分页)', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  // getMerchantList
  getMerchantList({ commit, state }, val) {
    return new Promise((resolve, reject) => {
      const data = {
        page: val ? val.page : 1,
        page_size: val ? val.page_size : 10,
        name: val ? val.name : ''
      }
      getMerchantList(data)
        .then((response) => {
          const data = response.data;
          // console.log('getMerchantList', data);
          if (data.code === 200) {
            commit("SET_MERCHANT_LIST", data.data);
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // addMerchant
  addMerchant({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addMerchant(data)
        .then((response) => {
          const data = response.data;
          // console.log('addMerchant', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // setPathTo
  setPathTo({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_PATH_TO", data);
    });
  },




  // remove OAuth
  removeOAuth({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      removeOAuth(id)
        .then((response) => {
          const data = response.data;
          // console.log('removeOAuth', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get Big Data
  getBigData({ commit, state }) {
    return new Promise((resolve, reject) => {
      getBigData().then((response) => {
        const data = response.data;
        console.log('get Big Data', data);
        if (data.code === 200) {
          resolve(data.data);
        } else {
          reject(data.message);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  },



  // // ggetMaterial
  // getMaterial({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getMaterial().then((response) => {
  //       const data = response.data;
  //       console.log('获取素材', data.data);
  //       if (data.code === 200) {
  //         commit("SET_MATERIAL_DATA", data.data);
  //         resolve(data.data);
  //       } else {
  //         reject(data.message);
  //       }
  //     })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },


  // getOemSetting
  getOemSetting({ commit, state }) {
    return new Promise((resolve, reject) => {
      getOemSetting().then((response) => {
        const data = response.data;
        // console.log('获取OEM设置', data);
        if (data.code === 200) {
          resolve(data.data);
        } else {
          reject(data.message);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 修改OEM设置
  setOemSetting({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      setOemSetting(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改OEM设置', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 商户续期
  MerchantRenewal({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      MerchantRenewal(data)
        .then((response) => {
          const data = response.data;
          // console.log('商户续期', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  




  // 修改商户密码
  MerchantChangePwd({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      MerchantChangePwd(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改商户密码', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },






  // getPackageList
  getPackageList({ commit, state }) {
    return new Promise((resolve, reject) => {
      getPackageList().then((response) => {
        const data = response.data;
        // console.log('get 套餐列表', data);
        if (data.code === 200) {
          resolve(data.data);
        } else {
          reject(data.message);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  },






  // get SevenDay Data
  getSevenDayData({ commit, state }) {
    return new Promise((resolve, reject) => {
      getSevenDayData().then((response) => {
        const data = response.data;
        console.log('get SevenDay Data', data);
        if (data.code === 200) {
          resolve(data.data);
        } else {
          reject(data.message);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  },









  // changePassword
  changePassword({ commit, state }, val) {
    // console.log('修改密码接收', val);
    return new Promise((resolve, reject) => {
      changePassword(val).then((res) => {
        // console.log('修改密码响应', res);
        const data = res.data
        if (data.code === 200) {
          resolve('修改成功')
        } else {
          reject(data.message)
        }
      }).catch((error) => {
        reject(error);
      });
    });
  },
  // user logout
  logout({ commit, state }) {
    // console.log('退退退');
    return new Promise((resolve) => {
      removeToken() // must remove  token  first
      commit("RESET_STATE");
      localStorage.removeItem("keywords")
      resolve();
      // history.go(0)
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      // removeToken() // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },

  // 记住密码
  // rememberPwd({ commit }, val) {
  //   return new Promise((resolve) => {
  //     // removeToken() // must remove  token  first
  //     commit("SET_REMEMBER", val);
  //   });
  // },

  // setToKen({ commit }, val) {
  //   return new Promise((resolve) => {
  //     commit("SET_TOKEN", val);
  //     resolve(true)
  //   }).catch((error) => {
  //     reject(error);
  //   });
  // },

  // 修改信息
  setUserEdit({ commit, state }, data) {
    return new Promise((resolve,reject) => {
      setUserEdit(data).then((res) => {
        // console.log('修改信息', res.data);
        const data = res.data
        if (data.code === 200) {
          resolve(data.data)
        } else {
          reject(data.message)
        }
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
