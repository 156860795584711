
import { getOrderList, addOrder, updateOrder, orderAgree, orderReject } from '@/api/order'
const getDefaultState = () => {
  return {

  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
}
const actions = {
  resetState({ commit }) {
    return new Promise((resolve) => {
      commit('RESET_STATE')
    })
  },

  // 获取订单列表
  getOrderList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getOrderList(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取订单列表', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 添加订单
  addOrder({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addOrder(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加订单', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 修改订单
  updateOrder({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateOrder(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改订单', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 订单审核通过
  orderAgree({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      orderAgree(data)
        .then((response) => {
          const data = response.data;
          // console.log('订单审核通过', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 订单审核拒绝
  orderReject({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      orderReject(data)
        .then((response) => {
          const data = response.data;
          // console.log('订单审核拒绝', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
