
import { getReplyAudioGroupNoPaging, getReplyAudioGroup, addReplyAudioGroup, updateReplyAudioGroup, deleteReplyAudioGroup, getReplyAudio, addReplyAudio, updateReplyAudio, deleteReplyAudio } from '@/api/replyAudio'
const getDefaultState = () => {
  return {
    reply_audio_keywords: [],
  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_REPLY_AUDIO_KEYWORDS: (state, reply_audio_keywords) => {
    state.reply_audio_keywords = reply_audio_keywords;
  },
}
const actions = {
  resetState({ commit }) {
    return new Promise((resolve) => {
      commit('RESET_STATE')
    })
  },

  //获取回复音频分组(无分组)
  getReplyAudioGroupNoPaging({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getReplyAudioGroupNoPaging(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取回复音频分组', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //获取回复音频分组
  getReplyAudioGroup({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getReplyAudioGroup(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取回复音频分组', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  //添加回复音频分组
  addReplyAudioGroup({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addReplyAudioGroup(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加回复音频分组', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  //修改回复音频分组
  updateReplyAudioGroup({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateReplyAudioGroup(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改回复音频分组', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  //删除回复音频分组
  deleteReplyAudioGroup({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      deleteReplyAudioGroup(data)
        .then((response) => {
          const data = response.data;
          // console.log('删除回复音频分组', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 获取回复音频
  getReplyAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getReplyAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取回复音频', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 添加回复音频
  addReplyAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addReplyAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加回复音频', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 修改回复音频
  updateReplyAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateReplyAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改回复音频', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 删除回复音频
  deleteReplyAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      deleteReplyAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('删除回复音频', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 存储回复音频关键字
  setReplyAudioKeyword({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_REPLY_AUDIO_KEYWORDS", data);
    });
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
