/*
 * @Author: D18
 * @Date: 2021-10-14 19:11:07
 * @LastEditors: D18
 * @LastEditTime: 2022-07-07 14:57:30
 * @FilePath: \douyin_admin_v2f:\DeskTop\wl-admin\ai_cai_gou\src\utils\axios.js
 */
import Vue from 'vue'
import axios from 'axios'
import { Message, Notification } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
// create an axios instance
// const baseURL = process.env.NODE_ENV === 'development' ? 'http://shuju.beta.wl1288.cn/api/manage' : 'http://shuju.beta.wl1288.cn/api/manage'
// const baseURL1 = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_REQUEST_URL
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: baseURL1,
  // baseURL: baseURL,
  timeout: 10 * 10000 // request timeout
})

/* 配置全局loading */
let loading = false
let needLoadingRequestCount = 0 // 声明一个对象用于存储请求个数
function startLoading(loadingDom) {
  loading = Vue.prototype.$loading({
    lock: true,
    text: '拼命加载中，请耐心等待...',
    background: 'rgba(255, 255, 255, 0.7)',
    target: loadingDom || '.app-main' // 设置加载动画区域
  })
}
function endLoading() {
  loading && loading.close()
}
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}
function hideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

/* export const service2 = axios.create({
  baseURL: '',
  timeout: 10 * 10000 // request timeout
})
 */
// request interceptor
service.interceptors.request.use(
  (config) => {
    // 设置 loading
    // console.log('config.isLoading_loading', config.isLoading);
    if (config.isLoading !== false) {
      // 如果配置了isLoading: false，则不显示loading
      showFullScreenLoading()
    }
    // do something before request is sent
    // console.log('store.getters.token', store.getters.token);
    // console.log('getToken()', getToken());
    // console.log('location.origin', location.origin);
    // console.log('config.mode', config.mode);
    // config.headers['From'] = location.origin
    config.headers['From'] = location.hostname
    const token = store.getters.token || getToken()
    // 携带请求头
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    if (config.mode) {
      config.baseURL = process.env[config.mode]
      delete config.headers.Authorization
    }
    return config
  },
  (error) => {
    hideFullScreenLoading()
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response
    // console.log('res.status', res.status);
    if (
      res.status !== 200 &&
      res.status !== 201 &&
      res.status !== 203 &&
      res.status !== 204
    ) {
      Message({
        // message: '请求错误，请退出重试' || res.data.msg || res.data.error,
        message: res.data.message,
        type: 'error',
        duration: 3 * 1000
      })

      return false
    } else {
      // 关闭 loading
      hideFullScreenLoading() // 响应成功关闭loading
      return res
    }
  },
  (error) => {
    // 关闭 loading
    hideFullScreenLoading()
    const err = error.response
    console.log('🌟 ~ error', error) // for debug
    console.log('🌟 ~ err', err) // for debug
    if (err.status === 401) {
      Message({
        // message: '未授权：登录失败,请刷新页面,重新确认账号或密码',
        message: err.data.message,
        type: 'error',
        duration: 3000,
        onClose: () => {
          // history.go(0)
        }
      })

      return Promise.reject(error)
    } else if (err.status === 422) {
      const { data } = err
      let str = ''
      for (const key in data.errors) {
        str += `${key}---${data.errors[key]}<hr/>`
      }
      Notification.error({
        dangerouslyUseHTMLString: true,
        title: data.message,
        message: str,
        duration: 3000,
        onClose: () => {
          // history.go(0)
        }
      })
      return Promise.reject(error)
    } else if (err.status === 423) {
      Message({
        // message: '未授权：您没有当前操作权限',
        message: err.data.message || '未授权：您没有当前操作权限',
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(error)
    } else if (err.status === 502 || err.status === 413) {
      if (err.config.url.indexOf('uploadFile') !== -1) {
        Message({
          message: '文件大小不能超过4M, 请压缩文件后重试...',
          type: 'error',
          duration: 5 * 1000
        })
      }
    } else if (err.status === 500) {
      // Message({
      //   message: '服务器内部错误, 请联系管理员!',
      //   // message: err.data.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      console.log(error);
      return Promise.reject(error)
    } else {
      Message({
        // message: '请求错误，请刷新重试' || err.data.msg || err.data.error || err.data,
        message: err.data.message || '请求错误，请刷新重试',
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service
