import request from '@/utils/axios.js'



// 获取直播记录列表
export function getLiveRecordList(data) {
  return request({
    url: '/liveRecord',
    method: 'get',
    params: data
  })
}


// 获取记录详情
export function getLiveRecord(id) {
  return request({
    url: `/liveRecord/${id}`,
    method: 'get',
  })
}




// 获取直播贴图列表
export function getChartletList(data) {
  return request({
    url: '/liveChartlet',
    method: 'get',
    params: data
  })
}

// 添加直播贴图
export function addChartlet(data) {
  return request({
    url: '/liveChartlet',
    method: 'post',
    data
  })
}

// 删除直播贴图
export function deleteChartlet(id) {
  return request({
    url: `/liveChartlet/${id}`,
    method: 'delete',
  })
}

