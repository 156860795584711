
import { getAudioPacket, getAudioPacketNoPaging, addAudioPacket, updateAudioPacket, deleteAudioPacket, getSettingDetails, settingModification, getAudioList, addAudio, editAudio, deleteAudio, creativeAiText, getAiText, creativeXFAiText, getXFAiText, getSts, getStsToken, getVoiceList, genTTS, getTTS, getFinancialList } from '@/api/menu'
const getDefaultState = () => {
  return {
    aside_show: false,
    setting_details: {},
    popup_second: 1,
    switching_merchant: true,
    audio_list: {},
    sts: {},
  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_ASIDE_SHOW: (state, aside_show) => {
    state.aside_show = aside_show
  },
  SET_SETTING_DETAILS: (state, setting_details) => {
    state.setting_details = setting_details
  },
  SET_POPUP_SECOND: (state, popup_second) => {
    state.popup_second = popup_second
  },
  SET_SWITCHING_MERCHANT: (state, switching_merchant) => {
    state.switching_merchant = switching_merchant
  },
  SET_AUDIO_LIST: (state, audio_list) => {
    state.audio_list = audio_list
  },
  SET_STS: (state, sts) => {
    state.sts = sts
  },
}
const actions = {
  resetState({ commit }) {
    return new Promise((resolve) => {
      commit('RESET_STATE')
    })
  },
  asideShow({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_ASIDE_SHOW', data)
    })
  },

  //获取音频分组（无分页）
  getAudioPacketNoPaging({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAudioPacketNoPaging(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取音频分组（无分页）', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //获取音频分组
  getAudioPacket({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAudioPacket(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取音频分组', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //添加音频分组
  addAudioPacket({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addAudioPacket(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加音频分组', data);
          if (data.code === 200) {
            // commit("SET_SETTING_DETAILS", data.data);
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  //修改音频分组
  updateAudioPacket({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateAudioPacket(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改音频分组', data);
          if (data.code === 200) {
            // commit("SET_SETTING_DETAILS", data.data);
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //删除音频分组
  deleteAudioPacket({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      deleteAudioPacket(data)
        .then((response) => {
          const data = response.data;
          // console.log('删除音频分组', data);
          if (data.code === 200) {
            // commit("SET_SETTING_DETAILS", data.data);
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  //直播设置详情
  getSettingDetails({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getSettingDetails(data)
        .then((response) => {
          const data = response.data;
          // console.log('getSettingDetails', data);
          if (data.code === 200) {
            // commit("SET_SETTING_DETAILS", data.data);
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // settingDetails({ commit, state }, data) {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_SETTING_DETAILS', data)
  //     resolve()
  //   })
  // },
  // popupSecond({ commit, state }, data) {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_POPUP_SECOND', data)
  //     resolve()
  //   })
  // },



  switchingMerchant({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_SWITCHING_MERCHANT', data)
    })
  },


  //直播设置修改
  settingModification({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      settingModification(data)
        .then((response) => {
          const data = response.data;
          // console.log('getSettingDetails', data);
          if (data.code === 200) {
            resolve(data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //音频素材列表
  getAudioList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAudioList(data)
        .then((response) => {
          const data = response.data;
          // console.log('getAudioList', data);
          if (data.code === 200) {
            commit("SET_AUDIO_LIST", data.data);
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //添加音频
  addAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('addAudio', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 编辑音频
  editAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      editAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('editAudio', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 删除音频
  deleteAudio({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      deleteAudio(data)
        .then((response) => {
          const data = response.data;
          // console.log('deleteAudio menu', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  // 提交阿里创意文案生成
  creativeAiText({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      creativeAiText(data)
        .then((response) => {
          const data = response.data;
          // console.log('creativeAiText menu', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取阿里创意文案生成
  getAiText({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAiText(data)
        .then((response) => {
          const data = response.data;
          // console.log('getAiText menu', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 提交讯飞创意文案生成
  creativeXFAiText({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      creativeXFAiText(data)
        .then((response) => {
          const data = response.data;
          // console.log('creativeXFAiText menu', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  // 获取讯飞创意文案生成
  getXFAiText({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getXFAiText(data)
        .then((response) => {
          const data = response.data;
          // console.log('getXFAiText menu', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },






  //获取sts
  getSts({ commit, state }) {
    return new Promise((resolve, reject) => {
      getSts()
        .then((response) => {
          const data = response.data;
          // console.log('getSts', data);
          if (data.code === 200) {
            commit('SET_STS', data.data)
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  //获取sts TOKEN
  getStsToken({ commit, state }) {
    return new Promise((resolve, reject) => {
      getStsToken()
        .then((response) => {
          const data = response.data;
          // console.log('getStsToken', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },




  //获取getVoiceList
  getVoiceList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getVoiceList(data)
        .then((response) => {
          const data = response.data;
          // console.log('getVoiceList', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //TTS合成
  genTTS({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      genTTS(data)
        .then((response) => {
          const data = response.data;
          // console.log('genTTS', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  //获取getTTS
  getTTS({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getTTS(data)
        .then((response) => {
          const data = response.data;
          // console.log('getTTS', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  
  //获取getFinancialList
  getFinancialList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getFinancialList(data)
        .then((response) => {
          const data = response.data;
          // console.log('getFinancialList', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
