<template>
  <div class="box-aside">
    <div :class="['logo', { 'logo1': $store.getters.aside_show }]" @click="logoTo">
      <!-- <img
        :src="$store.getters.aside_show ? $store.getters.material_data.logo_img : $store.getters.material_data.heng_logo_img"
        alt=""> -->
      <img :src="$store.getters.aside_show ? require('@/assets/img/logo1.png') : require('@/assets/img/logo.png')" alt="">
    </div>
    <el-menu default-active="/GroupPurchaseAccount" background-color="#fff" text-color="#000" active-text-color="#409EFF"
      class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="$store.getters.aside_show" router>
      <el-scrollbar class="menu_scrollbar">
        <!-- <el-menu-item index="/Dashboard" :class="{ 'is_active': $store.getters.path_to === '/Dashboard' }">
        <i class="el-icon-menu"></i>
        <span slot="title">数据大屏</span>
      </el-menu-item> -->
        <el-menu-item index="/GroupPurchaseAccount"
          :class="{ 'is_active': $store.getters.path_to === '/GroupPurchaseAccount' }">
          <i class="el-icon-document"></i>
          <span slot="title" class="商户列表">商户列表</span>
        </el-menu-item>
        <!-- <el-menu-item index="/OrderList" :class="{ 'is_active': $store.getters.path_to === '/OrderList' }"
        v-if="$store.getters.identity !== 'customer'">
        <i class="el-icon-s-operation"></i>
        <span slot="title">订单列表</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/AgentList"
        v-if="$store.getters.identity === 'senior_agent' || $store.getters.identity === 'senior_oem_agent'"
        :class="{ 'is_active': $store.getters.path_to === '/AgentList' }">
        <i class="el-icon-document"></i>
        <span slot="title">代理商列表</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/BroadcastMerchant" :class="{ 'is_active': $store.getters.path_to === '/BroadcastMerchant' }">
        <i class="el-icon-setting"></i>
        <span slot="title">代播商户</span>
      </el-menu-item> -->
        <el-submenu index="1" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <!-- <i class="el-icon-location"></i> -->
            <i class="iconfont alico-shujujuzhen"></i>
            <!-- <span :style="$store.getters.aside_show ? 'display:none;' : 'display:block;'">{{$store.getters.aside_show}}导航一</span> -->
            <span>导航一</span>
          </template>
          <el-submenu index="1-4">
            <template slot="title">
              <i class="iconfont alico-shujujuzhen"></i>
              选项1</template>
            <el-menu-item index="/MaterialManagement">
              <i class="iconfont alico-shujujuzhen"></i>
              授权
            </el-menu-item>
            <el-menu-item>
              <a href="https://open.douyin.com/platform/oauth/connect/?client_key=awqba2qjk362vhex&response_type=code&scope=user_info%2Cvideo.create.bind&redirect_uri=https://aicaigou-server.0551seo.cn/adminapi/douyin.douyin/callback">
                qqqq
              </a>
              
              </el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="2" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航2</span>
          </template>
          <el-submenu index="2-4">
            <template slot="title">选项2</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="2">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="3" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航3</span>
          </template>
          <el-submenu index="3-4">
            <template slot="title">选项3</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="3">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="4" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航4</span>
          </template>
          <el-submenu index="4-4">
            <template slot="title">选项4</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="4">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="5" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航5</span>
          </template>
          <el-submenu index="5-4">
            <template slot="title">选项5</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="5">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="6" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航6</span>
          </template>
          <el-submenu index="6-4">
            <template slot="title">选项6</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="6">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="7" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航7</span>
          </template>
          <el-submenu index="7-4">
            <template slot="title">选项7</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="7">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-submenu index="8" :class="{ 'submenu_title_and_icon': $store.getters.aside_show }">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>导航8</span>
          </template>
          <el-submenu index="8-4">
            <template slot="title">选项8</template>
            <el-menu-item index="/MaterialManagement">素材管理</el-menu-item>
            <el-menu-item index="8">素材管理</el-menu-item>
          </el-submenu>
        </el-submenu>
        <!-- <el-menu-item index="/MaterialManagement" :class="{ 'is_active': $store.getters.path_to === '/MaterialManagement' }"
        v-if="$store.getters.identity !== 'senior_agent' && $store.getters.identity !== 'senior_oem_agent'">
        <i class="el-icon-menu"></i>
        <span slot="title">素材管理</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/DataOverview" :class="{ 'is_active': $store.getters.path_to === '/DataOverview' }">
        <i class="el-icon-document"></i>
        <span slot="title">数据概览</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/IntelligentCustomerService"
        :class="{ 'is_active': $store.getters.path_to === '/IntelligentCustomerService' }">
        <i class="el-icon-setting"></i>
        <span slot="title">智能客服</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/LiveRecord" :class="{ 'is_active': $store.getters.path_to === '/LiveRecord' }">
        <i class="el-icon-document"></i>
        <span slot="title">直播记录</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/FinancialJournal" :class="{ 'is_active': $store.getters.path_to === '/FinancialJournal' }"
        v-if="$store.getters.identity !== 'customer' && ($store.getters.user_info ? $store.getters.user_info.agent.parent_id === 0 : false)">
        <i class="el-icon-c-scale-to-original"></i>
        <span slot="title">财务日志</span>
      </el-menu-item> -->
        <el-menu-item index="/FinancialJournal" :class="{ 'is_active': $store.getters.path_to === '/FinancialJournal' }">
          <i class="el-icon-c-scale-to-original"></i>
          <span slot="title">财务日志</span>
        </el-menu-item>
        <!-- <el-menu-item index="/PersonalSetting" :class="{ 'is_active': $store.getters.path_to === '/PersonalSetting' }">
        <i class="el-icon-setting"></i>
        <span slot="title">个人设置</span>
      </el-menu-item> -->
        <!-- <el-menu-item index="/HeyAssistant" :class="{ 'is_active': $store.getters.path_to === '/HeyAssistant' }">
        <i class="el-icon-chat-dot-round"></i>
        <span slot="title">云伴精灵</span>
      </el-menu-item> -->
        <!-- <el-submenu index="系统配置">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span slot="title">系统配置</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="LogManagement"
            :class="{ 'is_active': $store.getters.path_to === '/LogManagement' }">日志管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
      </el-scrollbar>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "CommonAside",
  data() {
    return {
    };
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // logo跳转
    logoTo() {
      this.$router.push(this.$store.getters.path)
    },
  }
}
</script>

<style lang="scss" scoped>
.box-aside {
  height: 100%;
  width: 100%;

  // color: #545c64;
  // color: #ffd04b;
  // color: rgb(84, 92, 100);
  .logo {
    height: 40px;
    width: auto;
    font-size: 0;
    padding: 2.5px 10px;
    text-align: center;
    margin: 0 auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .logo1 {
    width: 40px;
    padding: 2.5px 0;
  }

  ::v-deep .el-menu-vertical-demo {
    height: calc(100% - 40px);

    .menu_scrollbar {
      height: 100%;
      width: 100%;

      .el-scrollbar__wrap {
        overflow-x: hidden;

        .submenu_title_and_icon {
          .el-submenu__title {
            span {
              display: none;
            }

            .el-submenu__icon-arrow {
              display: none;
            }
          }

        }

      }

      .is-horizontal {
        display: none;
      }
    }

    // .el-menu-item.is_active{
    //   background-color: blueviolet;
    //   color: #5887fb;
    // }
    // .el-menu-item:hover{
    //   background-color: #fff;
    //   color: brown;
    // }
    //   .el-menu-item {
    //     color: #3b3d3f;

    //     i {
    //       color: #3b3d3f;
    //     }
    //   }

    // .is_active {
    //   color: #409EFF !important;

    //   i {
    // color: #409EFF !important;
    //   }
    // }

    //   .el-submenu {
    //     .el-menu--inline {
    //       .el-menu-item-group {
    //         ul {
    //           .el-menu-item {
    //             color: #3b3d3f;
    //           }

    //           .is_active {
    //             color: #409EFF !important;
    //           }
    //         }
    //       }
    //     }
    //   }
  }
}
</style>