
import { getLiveRecordList, getLiveRecord, getChartletList, addChartlet, deleteChartlet } from '@/api/chartlet'
const getDefaultState = () => {
  return {

  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
}
const actions = {
  resetState({ commit }) {
    return new Promise((resolve) => {
      commit('RESET_STATE')
    })
  },


  // 获取记录详情
  getLiveRecord({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getLiveRecord(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取记录详情', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取直播记录列表
  getLiveRecordList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getLiveRecordList(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取直播记录列表', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取直播贴图列表
  getChartletList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getChartletList(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取直播贴图列表', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 添加直播贴图
  addChartlet({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addChartlet(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加直播贴图素材', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 删除直播贴图
  deleteChartlet({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      deleteChartlet(data)
        .then((response) => {
          const data = response.data;
          // console.log('删除直播贴图', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
