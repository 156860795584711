import request from '@/utils/axios.js'

// 获取音频分组
export function getAudioPacket(data) {
  return request({
    url: '/live_audio_group',
    method: 'get',
    params: data
  })
}


// 获取音频分组（无分页）
export function getAudioPacketNoPaging(data) {
  return request({
    url: '/live_audio_group/list',
    method: 'get',
    params: data
  })
}

// 添加音频分组
export function addAudioPacket(data) {
  return request({
    url: '/live_audio_group',
    method: 'post',
    data
  })
}

// 修改音频分组
export function updateAudioPacket(data) {
  return request({
    url: `/live_audio_group/${data.id}`,
    method: 'put',
    data: {
      name: data.name
    }
  })
}

// 删除音频分组
export function deleteAudioPacket(data) {
  return request({
    url: `/live_audio_group/${data.id}`,
    method: 'delete',
  })
}

// 直播设置详情
export function getSettingDetails(data) {
  return request({
    url: '/liveSetting',
    method: 'get',
    params: data
  })
}

// 直播设置修改
export function settingModification(data) {
  return request({
    url: '/liveSetting',
    method: 'put',
    data
  })
}

// 音频素材列表
export function getAudioList(data) {
  return request({
    url: '/live_audio',
    method: 'get',
    params: data
  })
}

// 添加音频
export function addAudio(data) {
  return request({
    url: '/live_audio',
    method: 'post',
    data
  })
}

// 编辑音频
export function editAudio(data) {
  return request({
    url: `/live_audio/${data.id}`,
    method: 'put',
    data: data.data
  })
}


// 删除音频
export function deleteAudio(id) {
  return request({
    url: `/live_audio/${id}`,
    method: 'delete',
  })
}

// 提交阿里创意文案生成
export function creativeAiText(data) {
  return request({
    url: '/ai/yb_ai_text',
    method: 'post',
    isLoading: false,
    data
  })
}

// 获取阿里创意文案生成
export function getAiText(data) {
  return request({
    url: '/ai/get_yb_ai_text',
    method: 'post',
    isLoading: false,
    data
  })
}

// 提交讯飞创意文案生成
export function creativeXFAiText(data) {
  return request({
    url: '/ai/xf_ai_text',
    method: 'post',
    data
  })
}

// 获取讯飞创意文案生成
export function getXFAiText(data) {
  return request({
    url: '/ai/get_xf_ai_text',
    method: 'post',
    isLoading: false,
    data
  })
}

// 获取sts
export function getSts() {
  return request({
    url: '/ali/get_sts',
    method: 'get',
  })
}

// 获取sts TOKEN
export function getStsToken() {
  return request({
    url: '/ali/get_token',
    method: 'get',
  })
}

// 获取语音列表
export function getVoiceList(data) {
  return request({
    url: '/ali/voice',
    method: 'get',
    params: data
  })
}

// TTS合成
export function genTTS(data) {
  return request({
    url: '/ali/gen_tts',
    method: 'post',
    data
  })
}

// 获取TTS合成结果
export function getTTS(data) {
  return request({
    url: '/ali/get_tts',
    method: 'get',
    params: data
  })
}






// 将url转数据流
export function downFile(url, parameter) {
  return request({
    url: url,
    method: 'get',
    data: JSON.stringify(parameter),
    responseType: 'blob'
  })
}



// 获取财务流水列表
export function getFinancialList(data) {
  return request({
    url: '/finance_log',
    method: 'get',
    params: data
  })
}
