import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import echarts from './utils/echarts';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/index.css';
import '@/assets/css/icon/iconfont.css';
import '@/permission' // permission control
// import { swiper } from 'swiper/vue';  // Import Swiper Vue.js components
import VueAwesomeSwiper from 'vue-awesome-swiper';  // Import Swiper Vue.js components
import 'swiper/css/swiper.min.css'; // Import Swiper styles
Vue.use(ElementUI);
// Vue.use(swiper);
Vue.use(VueAwesomeSwiper);

// 挂载到vue实例中
Vue.prototype.$echarts = echarts
// Vue.prototype.$swiper = VueAwesomeSwiper

Vue.config.productionTip = false
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
