<template>
  <div class="common-head">
    <div class="common-head-top">
      <div class="aside_show" @click="asideShow">
        <i :class="$store.getters.aside_show ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
      </div>
      <!-- 账号头像 -->
      <div class="head-user">
        <el-dropdown>
          <div class="user-info">
            <div class="info_img">
              <!-- <img :src="$store.getters.avatar" alt="" class="avatar" /> -->
              <img src="@/assets/img/yunban.png" alt="" class="avatar" />
              <!-- <img :src="$store.getters.material_data.avatar_img" alt="" class="avatar" /> -->
              <!-- <img :src="$store.getters.avatar ? '@/assets/img/yunban.png' : avatar" alt="" class="avatar" /> -->
            </div>
            <span>{{ $store.getters.name }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item @click.native="OEMopen"
              v-if="$store.getters.identity === 'oem_agent' || $store.getters.identity === 'senior_oem_agent'">OEM设置</el-dropdown-item> -->
            <!-- <el-dropdown-item @click.native="dialogVisible = true">修改密码</el-dropdown-item> -->
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog title="OEM设置" class="OEMdialog" :visible.sync="OEMVisible" width="60%" :before-close="OEMClose" top="2vh">
      <el-form class="OEMform" ref="OEM-Form" :model="OEMform" label-width="140px" label-position="right"
        :rules="OEMrules">
        <el-form-item label="域名" prop="domain">
          <el-input v-model.trim="domain" placeholder="请输入域名(只能是纯英文)" disabled></el-input>
        </el-form-item>
        <el-form-item label="系统名称" prop="system_name">
          <el-input v-model.trim="OEMform.system_name" placeholder="请输入系统名称"></el-input>
        </el-form-item>
        <el-form-item v-for="(item, index) in OEMformImgList" :key="index" :label="item.label" :prop="item.prop"
          class="OEMform_upload">
          <template>
            <el-upload class="upload-demo" action="" :on-change="(file, fileList,) => loginChange(file, fileList, index,)"
              :file-list="item.fileList" :auto-upload="false" :accept="item.accept" :show-file-list="false"
              list-type="picture">
              <el-button v-preventReClick slot="trigger" size="small" type="primary"
                :disabled="!item.disabledBtn">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传{{ item.hint }}文件</div>
            </el-upload>
            <div class="thumbnail_list">
              <div class="thumbnail_item" v-for="(t, i) in item.fileList" :key="i">
                <div class="thumbnail_img">
                  <img :src="t.url" alt="">
                </div>
                <div class="thumbnail_main">
                  <div class="thumbnail_title" v-show="!item.progressShow">{{ t.name }}</div>
                  <el-progress class="thumbnail_progress" :percentage="t.percentage" :status="item.progressStatus"
                    v-show="t.percentage !== 0"></el-progress>
                </div>
                <i class="el-icon-close thumbnail_icon" v-show="t.percentage === 0"
                  @click="thumbnailItemRemove(index, i)"></i>
              </div>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-preventReClick @click="OEMClose">取 消</el-button>
        <el-button v-preventReClick :disabled="uploadBtn" type="primary" @click="submitUpload">一键上传</el-button>
        <el-button v-preventReClick type="primary" @click="onSubmitOEM(OEMform)">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="原密码" prop="old_pwd">
          <el-input v-model.trim="form.old_pwd" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new_pwd">
          <el-input v-model.trim="form.new_pwd" placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-preventReClick @click="exit">取 消</el-button>
        <el-button v-preventReClick type="primary" @click="onSubmit(form)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { validateDomain } from '@/utils/validate'
const OSS = require("ali-oss");// 引入插件
const uuid = require("uuid");
export default {
  name: "CommonHead",
  data() {
    return {
      avatar: require('@/assets/img/defaultAvatar.png'),
      dialogVisible: false,
      form: {
        old_pwd: '',
        new_pwd: ''
      },
      rules: {
        old_pwd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur' }
        ],
        new_pwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur' }
        ],
      },
      OEMVisible: false,
      domain: '',
      OEMform: {
        // domain: '',
        system_name: '',
        login_img: '',
        icon_img: '',
        avatar_img: '',
        logo_img: '',
        heng_logo_img: '',
      },
      OEMrules: {
        // domain: [
        //   { required: true, trigger: 'blur', validator: validateDomain },
        // ],
        system_name: [
          { required: true, message: '请输入系统名称', trigger: 'blur' },
        ],
        login_img: [
          { required: true, message: '请上传登录大图', trigger: 'blur' },
        ],
        icon_img: [
          { required: true, message: '请上传icon图标', trigger: 'blur' },
        ],
        avatar_img: [
          { required: true, message: '请上传头像', trigger: 'blur' },
        ],
        logo_img: [
          { required: true, message: '请上传logo(小，不带字)', trigger: 'blur' },
        ],
        heng_logo_img: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
        ],
      },
      OEMformImgList: [
        {
          label: '头像',
          prop: 'avatar_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: '登录大图',
          prop: 'login_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: 'icon图标',
          prop: 'icon_img',
          fileList: [],
          accept: '.ico',
          hint: 'ico',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: 'logo(大，带字)',
          prop: 'heng_logo_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: 'logo(小，不带字)',
          prop: 'logo_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
      ],
      client: {},
      uploadBtn: true,
      selectState: true,
    };
  },
  components: {
  },
  watch: {},
  created() { },
  mounted() {
  },
  methods: {
    asideShow() {
      const aside_show = !this.$store.getters.aside_show
      this.$store.dispatch("menu/asideShow", aside_show);
    },
    logout() {
      localStorage.removeItem("keywords")
      this.$store.dispatch("menu/resetState");
      this.$store.dispatch("replyAudio/resetState");
      this.$store.dispatch("user/logout");
      this.$router.push(`/login`);
    },
    onSubmit(form) {
      this.$refs.form.validate((valid) => {
        // console.log('验证', valid);
        if (valid) {
          this.$store.dispatch('user/changePassword', form).then((res) => {
            // console.log('修改成功', res);
            if (res === '修改成功') {
              this.$message({
                message: res,
                type: 'success',
                duration: 2000
              })
              this.resetForm()
              this.dialogVisible = false
            }
          }).catch((err) => {
            console.log('修改失败', err);
            this.$message({
              message: err,
              type: 'error',
              duration: 2000
            })
          })
        }
      })
    },
    exit() {
      this.resetForm()
      this.dialogVisible = false
    },
    resetForm() {
      this.form = {
        old_pwd: '',
        new_pwd: '',
      }
      this.$refs["form"].resetFields();
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
          this.resetForm()
        })
        .catch(_ => { });
    },
    // 打开OEM设置
    OEMopen() {
      // this.resetOEMForm()
      this.$store.dispatch("user/getOemSetting").then((res) => {

        console.log('getOemSetting', res);
        this.domain = res.domain
        this.OEMform.system_name = res.system_name
        let arr = Object.values(res).filter(item => item === null)
        console.log('aarr', arr);
        this.OEMformImgList.forEach(e => e.fileList = [])
        if (arr.length === 0) {
          this.OEMform.login_img = res.login_img
          this.OEMform.icon_img = res.icon_img
          this.OEMform.avatar_img = res.avatar_img
          this.OEMform.logo_img = res.logo_img
          this.OEMform.heng_logo_img = res.heng_logo_img
          // this.OEMform = {
          //   domain: res.domain,
          //   system_name: res.system_name,
          //   login_img: res.login_img,
          //   icon_img: res.icon_img,
          //   avatar_img: res.avatar_img,
          //   logo_img: res.logo_img,
          //   heng_logo_img: res.heng_logo_img,
          // }
          console.log('this.OEMform', this.OEMform);
          this.OEMformImgList[0].url = res.avatar_img
          this.OEMformImgList[0].fileList.push({
            name: res.avatar_img,
            percentage: 0,
            status: 'success',
            url: res.avatar_img_url
          })
          this.OEMformImgList[1].url = res.login_img
          this.OEMformImgList[1].fileList.push({
            name: res.login_img,
            percentage: 0,
            status: 'success',
            url: res.login_img_url
          })
          this.OEMformImgList[2].url = res.icon_img
          this.OEMformImgList[2].fileList.push({
            name: res.icon_img,
            percentage: 0,
            status: 'success',
            url: res.icon_img_url
          })
          this.OEMformImgList[3].url = res.heng_logo_img
          this.OEMformImgList[3].fileList.push({
            name: res.heng_logo_img,
            percentage: 0,
            status: 'success',
            url: res.heng_logo_img_url
          })
          this.OEMformImgList[4].url = res.logo_img
          this.OEMformImgList[4].fileList.push({
            name: res.logo_img,
            percentage: 0,
            status: 'success',
            url: res.logo_img_url
          })
          console.log('this.OEMformImgList OEMopen', this.OEMformImgList);
          this.selectState = true
        } else {


          const data = this.$store.getters.material_data
          // this.domain = ''
          // this.OEMform.system_name = data.system_name
          this.OEMformImgList[0].fileList.push({
            name: '示例：头像',
            percentage: 0,
            status: 'success',
            url: data.avatar_img
          })
          this.OEMformImgList[1].url = res.login_img
          this.OEMformImgList[1].fileList.push({
            name: '示例：登录大图',
            percentage: 0,
            status: 'success',
            url: data.login_img
          })
          this.OEMformImgList[2].url = res.icon_img
          this.OEMformImgList[2].fileList.push({
            name: '示例：icon图标',
            percentage: 0,
            status: 'success',
            url: data.icon_img
          })
          this.OEMformImgList[3].url = res.heng_logo_img
          this.OEMformImgList[3].fileList.push({
            name: '示例：logo（大，带字）',
            percentage: 0,
            status: 'success',
            url: data.heng_logo_img
          })
          this.OEMformImgList[4].url = res.logo_img
          this.OEMformImgList[4].fileList.push({
            name: '示例：logo（小，不带字）',
            percentage: 0,
            status: 'success',
            url: data.logo_img
          })
          this.selectState = false
        }
        this.OEMVisible = true
      }).catch((err) => {
        this.$message({
          message: err,
          type: "error",
          duration: 2000,
        });
      })
    },
    // 关闭OEMdialog
    OEMClose() {
      this.resetOEMForm()
      this.OEMVisible = false
    },
    // 删除缩略图  第一次进来
    thumbnailItemRemove(val, index) {
      // console.log('this.OEMformImgList[val]', this.OEMformImgList[val]);
      const item = this.OEMformImgList[val]
      item.fileList.splice(index, 1)
      let arr = this.OEMformImgList.filter(t => t.label === item.label)
      if (arr[0].label === '头像') {
        this.OEMform.avatar_img = ''
      } else if (arr[0].label === '登录大图') {
        this.OEMform.login_img = ''
      } else if (arr[0].label === 'icon图标') {
        this.OEMform.icon_img = ''
      } else if (arr[0].label === 'logo(小，不带字)') {
        this.OEMform.logo_img = ''
      } else if (arr[0].label === 'logo(大，带字)') {
        this.OEMform.heng_logo_img = ''
      }
      item.disabledBtn = true
      this.uploadBtn = true
    },
    // 选择文件变化
    loginChange(val, fileList, index) {
      console.log('OEMformImgList[index]', this.OEMformImgList[index]);
      console.log('handleChange val', val);
      console.log('handleChange t', fileList);
      console.log('handleChange i', index);
      const item = this.OEMformImgList[index]
      const result = item.accept.indexOf(val.name.slice(-4))
      if (result !== -1) {
        item.fileList = []
        item.fileList.push(val)
        // item.disabledBtn = false
        item.progressShow = false

        // selectState为false数据为空
        // 有数据时修改一个都可以重新上传，没有数据时需要都选择好,才可以上传
        if (this.selectState) {
          this.uploadBtn = false
        } else {
          let arr = this.OEMformImgList.filter(t => t.fileList.length === 0)
          if (arr.length === 0) {
            this.uploadBtn = false
          } else {
            this.uploadBtn = true
          }
        }
        console.log('OEMformImgList', this.OEMformImgList);
        console.log('this.OEMform', this.OEMform);
      } else {
        this.$message({
          message: `只能上传${item.hint}文件`,
          type: "error",
          duration: 2000,
        });
      }
    },
    async submitUpload() {
      this.uploadBtn = true
      this.$store.dispatch("menu/getSts").then(async (res) => {
        console.log("menu/getSts", res);
        const sts = this.$store.getters.sts
        const access = this.$store.getters.sts.sts
        this.client = new OSS({
          // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
          region: sts.region,
          // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
          accessKeyId: access.AccessKeyId,
          accessKeySecret: access.AccessKeySecret,
          stsToken: access.SecurityToken,
          // refreshSTSToken: access.SecurityToken,
          // 填写Bucket名称。
          bucket: sts.bucket,
          secure: true,
          timeout: 1000 * 60 * 60
        });

        console.log('this.client', this.client);
        if (this.selectState) {
          for (let index = 0; index < this.OEMformImgList.length; index++) {
            const item = this.OEMformImgList[index]
            console.log('asdasdasd', item.fileList[0].url);
            console.log('asdasdasd', item.fileList[0].url.indexOf('blob:'));

            if (item.fileList[0].url.indexOf('blob:') !== -1) {
              item.progressShow = true
              let path = 'image/' + this.$store.getters.user_info.id + '/' + uuid.v4().replace(/-/g, '') + item.fileList[0].name.slice(-4)
              console.log('path', path);
              await this.client.multipartUpload(path, item.fileList[0].raw, {
                progress: (percentage) => {
                  item.fileList[0].percentage = Math.floor(percentage * 100); // 更新上传进度
                  console.log('更新上传进度', item.fileList[0].percentage);
                },
              }).then((res) => {
                console.log('putputput', res);
                if (res) {
                  item.disabledBtn = false
                  item.progressStatus = 'success'
                  item.url = res.name
                  console.log('res.name', res.name);
                  console.log('item.url', item.url);
                  console.log('this.OEMformImgList上传', this.OEMformImgList[index]);
                  let arr = this.OEMformImgList.filter(r => r.fileList[0].url.indexOf('blob:') !== -1)
                  console.log('arr', arr.length);
                  console.log('index', index + 1);
                  // if (index + 1 === arr.length) {
                  console.log('完成');
                  this.OEMformImgList.forEach(y => {
                    if (y.label === '头像') {
                      this.OEMform.avatar_img = y.url
                      console.log('头像', y.url);
                    } else if (y.label === '登录大图') {
                      this.OEMform.login_img = y.url
                    } else if (y.label === 'icon图标') {
                      this.OEMform.icon_img = y.url
                    } else if (y.label === 'logo(小，不带字)') {
                      this.OEMform.logo_img = y.url
                    } else if (y.label === 'logo(大，带字)') {
                      this.OEMform.heng_logo_img = y.url
                    }
                  })
                  console.log('this.OEMform', this.OEMform);
                  this.$message({
                    message: '上传成功',
                    type: "success",
                    duration: 2000,
                  });
                  this.uploadBtn = true
                  // }
                } else {
                  this.$message({
                    message: '上传失败',
                    type: "error",
                    duration: 2000,
                  });
                }
              }).catch((err) => {
                console.log('上传失败', err);
                this.$message({
                  message: err,
                  type: "error",
                  duration: 2000,
                });
              })
            } else {

            }
          }
        } else {
          for (let index = 0; index < this.OEMformImgList.length; index++) {
            const item = this.OEMformImgList[index]
            item.progressShow = true
            let path = 'image/' + this.$store.getters.user_info.id + '/' + uuid.v4() + item.fileList[0].name.slice(-4)
            console.log('path', path);
            await this.client.multipartUpload(path, item.fileList[0].raw, {
              progress: (percentage) => {
                item.fileList[0].percentage = Math.floor(percentage * 100); // 更新上传进度
                console.log('更新上传进度', item.fileList[0].percentage);
              },
            }).then((res) => {
              console.log('putputput', res);
              if (res) {
                item.disabledBtn = false
                item.progressStatus = 'success'
                item.url = res.name
                if (index + 1 === this.OEMformImgList.length) {
                  this.OEMformImgList.forEach(y => {
                    if (y.label === '头像') {
                      this.OEMform.avatar_img = y.url
                    } else if (y.label === '登录大图') {
                      this.OEMform.login_img = y.url
                    } else if (y.label === 'icon图标') {
                      this.OEMform.icon_img = y.url
                    } else if (y.label === 'logo(小，不带字)') {
                      this.OEMform.logo_img = y.url
                    } else if (y.label === 'logo(大，带字)') {
                      this.OEMform.heng_logo_img = y.url
                    }
                  })
                  this.$message({
                    message: '上传成功',
                    type: "success",
                    duration: 2000,
                  });
                }
              } else {
                this.$message({
                  message: '上传失败',
                  type: "error",
                  duration: 2000,
                });
              }
            }).catch((err) => {
              console.log('上传失败', err);
              this.$message({
                message: err,
                type: "error",
                duration: 2000,
              });
            })
          }
        }
      }).catch((err) => {
        this.uploadBtn = false
        this.$message({
          message: err,
          type: "error",
          duration: 2000,
        });
      })
    },
    onSubmitOEM(OEMform) {
      console.log('OEMform', OEMform);
      this.$refs['OEM-Form'].validate((valid) => {
        console.log('OEMform', valid);
        if (valid) {
          this.$store.dispatch("user/setOemSetting", OEMform).then((res) => {
            console.log('setOemSetting', res);
            this.$message({
              message: '提交成功',
              type: "success",
              duration: 2000,
            });
            this.resetOEMForm()
            this.OEMVisible = false
            this.$router.go(0)
          }).catch((err) => {
            this.$message({
              message: err,
              type: "error",
              duration: 2000,
            });
          })
        } else {
          this.$message({
            message: '必填项不能为空或类型错误！',
            type: "error",
            duration: 2000,
          });
        }
      })
    },
    resetOEMForm() {
      this.domain = ''
      this.OEMform = {
        // domain: '',
        system_name: '',
        login_img: '',
        icon_img: '',
        avatar_img: '',
        logo_img: '',
        heng_logo_img: '',
      }
      this.OEMformImgList = [
        {
          label: '头像',
          prop: 'avatar_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: '登录大图',
          prop: 'login_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: 'icon图标',
          prop: 'icon_img',
          fileList: [],
          accept: '.ico',
          hint: 'ico',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: 'logo(大，带字)',
          prop: 'heng_logo_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
        {
          label: 'logo(小，不带字)',
          prop: 'logo_img',
          fileList: [],
          accept: '.png, .jpg',
          hint: 'jpg/png',
          percentage: 0,
          progressStatus: null,
          progressShow: false,
          disabledBtn: true,
          url: '',
        },
      ]
      this.uploadBtn = true
      this.$nextTick(() => {
        this.$refs["OEM-Form"].resetFields();
      })

    },
  },
};
</script>

<style lang="scss" scoped>
.common-head {

  .common-head-top {
    // background-color: #fff;
    height: 100%;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .aside_show {
      height: 100%;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        i {
          color: #2988fe;
        }
      }

      i {
        font-size: 20px;
      }

    }

    .head-user {
      // float: right;
      display: flex;
      align-items: center;

      .user-info {
        font-size: 12px;
        display: flex;
        align-items: center;
        padding-right: 15px;
        cursor: pointer;

        &:hover {
          span {
            color: #2988fe;
          }
        }

        .info_img {
          padding: 1px 0;
          margin-right: 8px;
          overflow: hidden;
          font-size: 0;
          box-sizing: border-box;
          border: none;

          img {
            border: 0;
            width: 40px;
            height: 40px;
            border: 1px solid #dcdcdc;
            border-radius: 20px;
          }
        }

        span {
          color: #000;
          font-size: 16px;
        }
      }
    }
  }

  ::v-deep .OEMdialog {
    .el-dialog {
      .el-dialog__body {
        padding: 5px 20px;

        .OEMform {
          .OEMform_upload {
            .el-form-item__content {
              display: flex;

              .upload-demo {
                width: 25%;
              }
            }

            .thumbnail_list {
              flex: 1;
              margin: 0;
              padding: 0;

              .thumbnail_item {
                width: 100%;
                overflow: hidden;
                z-index: 0;
                background-color: #fff;
                border: 1px solid #c0ccda;
                border-radius: 6px;
                box-sizing: border-box;
                margin-bottom: 10px;
                padding: 10px 10px 10px 10px;
                height: 92px;
                transition: all .5s cubic-bezier(.55, 0, .1, 1);
                font-size: 14px;
                color: #606266;
                line-height: 1.8;
                position: relative;
                display: flex;

                .thumbnail_img {
                  vertical-align: middle;
                  display: inline-block;
                  width: 70px;
                  height: 70px;
                  background-color: #fff;
                  font-size: 0;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .thumbnail_main {
                  flex: 1;
                  margin-top: 0;
                  margin-right: 30px;
                  padding-left: 15px;
                  display: flex;
                  align-items: center;

                  &:hover {
                    .thumbnail_title {
                      color: #2988fe;
                    }
                  }

                  .thumbnail_title {
                    color: #606266;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: color .3s;
                    white-space: nowrap;
                  }

                  .thumbnail_progress {
                    width: 100%;
                  }
                }

                .thumbnail_icon {
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  cursor: pointer;
                  opacity: .75;
                  color: #606266;
                }
              }
            }
          }
        }
      }
    }

  }
}

// .nick_name {
//   display: flex;
//   align-items: center;
// }
</style>
