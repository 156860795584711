
import { getAgentList, addAgent, updateAgent, getAgentParticulars, getAreaList } from '@/api/agent'
import { getMaterial } from "@/api/user";
const getDefaultState = () => {
  return {
    material_data: {},
  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_MATERIAL_DATA: (state, material_data) => {
    state.material_data = material_data;
  },
}
const actions = {
  resetState({ commit }) {
    return new Promise((resolve) => {
      commit('RESET_STATE')
    })
  },
  // ggetMaterial
  getMaterial({ commit, state }) {
    return new Promise((resolve, reject) => {
      getMaterial().then((response) => {
        const data = response.data;
        console.log('获取素材', data.data);
        if (data.code === 200) {
          commit("SET_MATERIAL_DATA", data.data);
          resolve(data.data);
        } else {
          reject(data.message);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取代理商列表
  getAgentList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAgentList(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取代理商列表', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 添加代理商素材
  addAgent({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addAgent(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加代理商素材', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 修改代理商
  updateAgent({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateAgent(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改代理商', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 代理商详情
  getAgentParticulars({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAgentParticulars(data)
        .then((response) => {
          const data = response.data;
          // console.log('代理商详情', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  // 获取地区列表
  getAreaList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getAreaList(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取地区列表', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
