
import { getVideoList, addVideo, updateVideo, deleteVideo } from '@/api/video'
const getDefaultState = () => {
  return {

  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
}
const actions = {
  resetState({ commit }) {
    return new Promise((resolve) => {
      commit('RESET_STATE')
    })
  },

  // 获取视频素材列表
  getVideoList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getVideoList(data)
        .then((response) => {
          const data = response.data;
          // console.log('获取视频素材列表', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 添加视频素材
  addVideo({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      addVideo(data)
        .then((response) => {
          const data = response.data;
          // console.log('添加视频素材', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  // 修改视频素材
  updateVideo({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      updateVideo(data)
        .then((response) => {
          const data = response.data;
          // console.log('修改视频素材', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 删除视频素材
  deleteVideo({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      deleteVideo(data)
        .then((response) => {
          const data = response.data;
          // console.log('删除视频素材', data);
          if (data.code === 200) {
            resolve(data.data);
          } else {
            reject(data.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
