import request from '@/utils/axios.js'


// 获取订单列表
export function getOrderList(data) {
  return request({
    url: '/order',
    method: 'get',
    params: data
  })
}

// 添加订单
export function addOrder(data) {
  return request({
    url: '/order',
    method: 'post',
    data
  })
}

// 修改订单
export function updateOrder(data) {
  return request({
    url: `/order/${data.id}`,
    method: 'put',
    data: data.data
  })
}

// 订单审核通过
export function orderAgree(data) {
  return request({
    url: '/order/agree',
    method: 'post',
    data
  })
}


// 订单审核拒绝
export function orderReject(data) {
  return request({
    url: '/order/reject',
    method: 'post',
    data
  })
}