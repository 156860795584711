import request from '@/utils/axios.js'

// 获取回复音频分组(无分组)
export function getReplyAudioGroupNoPaging(data) {
  return request({
    url: '/live_reply_audio_group/list',
    method: 'get',
    params: data
  })
}

// 获取回复音频分组
export function getReplyAudioGroup(data) {
  return request({
    url: '/live_reply_audio_group',
    method: 'get',
    params: data
  })
}


// 添加回复音频分组
export function addReplyAudioGroup(data) {
  return request({
    url: '/live_reply_audio_group',
    method: 'post',
    data
  })
}

// 修改回复音频分组
export function updateReplyAudioGroup(data) {
  return request({
    url: `/live_reply_audio_group/${data.id}`,
    method: 'put',
    data: {
      name: data.name
    }
  })
}

// 删除回复音频分组
export function deleteReplyAudioGroup(data) {
  return request({
    url: `/live_reply_audio_group/${data.id}`,
    method: 'delete',
  })
}

// 获取回复音频
export function getReplyAudio(data) {
  return request({
    url: '/live_reply_audio',
    method: 'get',
    params: data
  })
}

// 添加回复音频
export function addReplyAudio(data) {
  return request({
    url: '/live_reply_audio',
    method: 'post',
    data
  })
}

// 修改回复音频
export function updateReplyAudio(data) {
  return request({
    url: `/live_reply_audio/${data.id}`,
    method: 'put',
    data: data.data
  })
}



// 删除回复音频
export function deleteReplyAudio(id) {
  return request({
    url: `/live_reply_audio/${id}`,
    method: 'delete',
  })
}