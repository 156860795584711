/*
 * @Author: D18
 * @Date: 2020-07-29 15:56:18
 * @LastEditors: D18
 * @LastEditTime: 2022-07-13 17:27:20
 * @FilePath: \douyin_admin_v2f:\DeskTop\wl-admin\ai_cai_gou\src\store\getters.js
 */
const getters = {
  token: state => state.user.token,
  token1: state => state.user.token1,
  access_token: state => state.douyinUser.access_token,
  path: state => state.user.path,
  path_to: state => state.user.path_to,
  name: state => state.user.name,
  username: state => state.user.username,
  identity: state => state.user.identity,
  surplus_merchant: state => state.user.surplus_merchant,
  user_info: state => state.user.user_info,
  merchant_list: state => state.user.merchant_list,
  material_data: state => state.agent.material_data,
  aside_show: state => state.menu.aside_show,
  setting_details: state => state.menu.setting_details,
  popup_second: state => state.menu.popup_second,
  switching_merchant: state => state.menu.switching_merchant,
  audio_list: state => state.menu.audio_list,
  sts: state => state.menu.sts,
  reply_audio_keywords: state => state.replyAudio.reply_audio_keywords,
}
export default getters
