import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/layouts/Main.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      // {
      //   path: '/Dashboard',
      //   name: 'Dashboard',
      //   component: () => import('@/components/Dashboard.vue'),
      // },
      {
        path: '/GroupPurchaseAccount',
        name: 'GroupPurchaseAccount',
        component: () => import('@/components/ComPonents/GroupPurchaseAccount/GroupPurchaseAccount.vue'),
      },
      {
        path: '/OrderList',
        name: 'OrderList',
        component: () => import('@/components/ComPonents/OrderList/OrderList.vue'),
      },
      {
        path: '/AgentList',
        name: 'AgentList',
        component: () => import('@/components/ComPonents/AgentList/AgentList.vue'),
      },
      // {
      //   path: '/BroadcastMerchant',
      //   name: 'BroadcastMerchant',
      //   component: () => import('@/components/ComPonents/BroadcastMerchant/BroadcastMerchant.vue'),
      // },
      {
        path: '/MaterialManagement',
        name: 'MaterialManagement',
        component: () => import('@/components/ComPonents/MaterialManagement/MaterialManagement.vue'),
      },
      {
        path: '/DataOverview',
        name: 'DataOverview',
        component: () => import('@/components/ComPonents/DataOverview/DataOverview.vue'),
      },
      // {
      //   path: '/IntelligentCustomerService',
      //   name: 'IntelligentCustomerService',
      //   component: () => import('@/components/ComPonents/IntelligentCustomerService/IntelligentCustomerService.vue'),
      // },
      {
        path: '/LiveRecord',
        name: 'LiveRecord',
        component: () => import('@/components/ComPonents/LiveRecord/LiveRecord.vue'),
      },
      {
        path: '/FinancialJournal',
        name: 'FinancialJournal',
        component: () => import('@/components/ComPonents/FinancialJournal/FinancialJournal.vue'),
      },
      {
        path: '/PersonalSetting',
        name: 'PersonalSetting',
        component: () => import('@/components/ComPonents/PersonalSetting/PersonalSetting.vue'),
      },
      // {
      //   path: '/LogManagement',
      //   name: 'LogManagement',
      //   component: () => import('@/components/ComPonents/SystemConfiguration/LogManagement/LogManagement.vue'),
      // },
      {
        path: '/qqqq',
        name: 'qqqq',
        component: () => import('@/components/ComPonents/qqqq/qqqq.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/newLoin',
    name: 'newLoin',
    component: () => import('@/views/newLoin')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound')
  },

]
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router
