import Cookies from 'js-cookie'

const TokenKey = 'TikTok_Web_aimohe'
const AccessTokenKey = 'TikTok_aimohe'
const accountPassword = 'account_password_aimohe'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setAccessToken (access_token) {
  return Cookies.set(AccessTokenKey, access_token)
}

export function getAccountPassword() {
  return Cookies.get(accountPassword)
}

export function setAccountPassword(data) {
  return Cookies.set(accountPassword, data)
}

export function removeAccountPassword() {
  return Cookies.remove(accountPassword)
}
// export function getAccessToken () {
//   return Cookies.get(AccessTokenKey)
// }

// export function removeAccessToken () {
//   return Cookies.remove(AccessTokenKey)
// }