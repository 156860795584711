import request from '@/utils/axios.js'

// 登录
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}


// 获取当前账号token
export function getInfo() {
  return request({
    url: '/auth/authUser',
    method: 'get',
    isLoading: false,
  })
}

// 修改密码
export function changePassword(data) {
  return request({
    url: '/changePwd',
    method: 'post',
    data
  })
}
// 获取商户列表
export function getMerchantList(data) {
  return request({
    url: '/merchant',
    method: 'get',
    params: data
  })
}
// 获取商户列表(无分页)
export function getMerchantListNoPaging(data, tf) {
  return request({
    url: '/merchant/list',
    method: 'get',
    isLoading: tf,
    params: data
  })
}

// 添加商户
export function addMerchant(data) {
  return request({
    url: '/merchant',
    method: 'post',
    data
  })
}

// 商户续期
export function MerchantRenewal(data) {
  return request({
    url: '/merchant/renewal',
    method: 'post',
    data
  })
}

// 修改商户密码
export function MerchantChangePwd(data) {
  return request({
    url: '/merchant/changePwd',
    method: 'post',
    data
  })
}

// 获取套餐列表
export function getPackageList() {
  return request({
    url: '/package/list',
    method: 'get',
  })
}


// 获取素材
export function getMaterial(data) {
  return request({
    url: '/static',
    method: 'get',
    isLoading: false,
    params: data
  })
}


// 获取OEM设置
export function getOemSetting() {
  return request({
    url: '/agent/getOemSetting',
    method: 'get',
  })
}


// 修改OEM设置
export function setOemSetting(data) {
  return request({
    url: '/agent/setOemSetting',
    method: 'post',
    data
  })
}



// 修改信息
export function setUserEdit(data) {
  return request({
    url: '/user/edit',
    method: 'post',
    data
  })
}