import request from '@/utils/axios.js'


// 获取代理商列表
export function getAgentList(data) {
  return request({
    url: '/agent',
    method: 'get',
    params: data
  })
}

// 添加代理商
export function addAgent(data) {
  return request({
    url: '/agent',
    method: 'post',
    data
  })
}

// 修改代理商
export function updateAgent(data) {
  return request({
    url: `/agent/${data.id}`,
    method: 'put',
    data: data.data
  })
}


// 代理商详情
export function getAgentParticulars(id) {
  return request({
    url: `/agent/${id}`,
    method: 'get',
    isLoading: false,
  })
}


// 获取地区列表
export function getAreaList(data) {
  return request({
    url: '/area/list',
    method: 'get',
    isLoading: false,
    params: data
  })
}